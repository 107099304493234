<template>
    <Modal :modal-title="$t('auth.remind_pass')"
           :dialog="dialog"
           max-width="600px"
           :persistent="true"
           :closeIcon="false"
           dense
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ValidationObserver ref="loginForm"
                                tag="form"
                                @submit.prevent="sendReminder"
                                v-on:keyup.enter="sendReminder">
                    <p class="my-5">
                        {{ $t('auth.recovery_info') }}
                    </p>
                    <TextField
                        :label="$t('labels.login')"
                        name="login"
                        data-test="remindLoginInput"
                        :rules="{ required: true }"
                        v-model="login"
                    ></TextField>
            </ValidationObserver>
            <v-expand-transition>
                <v-card v-show="expand">
                    <v-card-title class="justify-center">
                        <v-icon
                            size="40"
                            color="medium_grey"
                        >{{ ICONS.INFORMATION }}</v-icon
                        >
                    </v-card-title>
                    <v-card-text>
                        <p class="text-center">
                            {{ confirmationText }}
                        </p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn v-if="!resetEmailSent" color="secondary" outlined @click="clearLoginInfo">
                            {{$t('buttons.ok')}}
                        </v-btn>
                        <v-btn v-else color="secondary" outlined @click="close">
                            {{$t('buttons.ok')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </template>

        <template v-if="!expand" slot="actions">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" color="secondary" outlined @click="close">
                {{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn color="secondary"
                   @click="sendReminder"
                   type="submit"
                   data-test="sendReminderBtn"
                   :loading="loader"
                   :disabled="loader">
                {{$t('buttons.send')}}
            </v-btn>
        </template>
    </Modal>

</template>

<script>
import Modal from '@/components/templates/Modal';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'changePasswordModal',
    components: { Modal, ValidationObserver, TextField },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        showConfirmation: true,
        loader: false,
        loginExist: true,
        expand: false,
        ICONS
    }),
    computed: {
        ...mapFields('authorization', ['login', 'resetEmailSent']),
        confirmationText () {
            return this.loginExist
                ? this.$t('auth.email_sent')
                : this.$t('auth.reminder_error');
        }
    },
    methods: {
        close () {
            this.login = '';
            this.showConfirmation = true;
            this.expand = false;
            this.$refs.loginForm.reset();
            this.$emit('close');
        },
        clearLoginInfo () {
            this.expand = !this.expand;
            this.login = '';
            this.$refs.loginForm.reset();
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        sendReminder () {
            this.loader = true;
            this.$refs.loginForm.validate().then(valid => {
                if (valid) {
                    return this.$store.dispatch('authorization/remindPassword')
                        .then(() => {
                            this.resetEmailSent = true;
                            this.loginExist = true;
                            this.expand = true;
                            this.loader = false;
                        })
                        .catch(error => {
                            if (error.response && error.response.status === 422) {
                                this.$store.commit('error/RESET_ERROR');
                                this.resetEmailSent = false;
                                this.expand = true;
                                this.loginExist = false;
                            }
                        })
                        .finally(() => {
                            this.loader = false;
                        });
                } else {
                    this.resetEmailSent = false;
                    this.loader = false;
                }
            });
        }
    }
};
</script>
