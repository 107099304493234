<template>
    <v-card>
        <v-layout v-if="host === 'superadmin'" align-center justify-center class="py-5">
            <v-icon
                size="50"
                color="blue">{{ securityIcon }}</v-icon>
        </v-layout>

        <v-card-title>{{ $t("auth.login") }}</v-card-title>
        <v-card-text class="pb-0" v-on:keyup.enter="authorise">
            <TextField v-model="login"
                name="login"
                :label="$t('labels.login')"
            ></TextField>
            <TextField
                v-model="password"
                slotName="append"
                name="password"
                :type="passwordFieldType"
                :label="$t('labels.password')"
            >
                <template slot="slotContent">
                    <v-icon
                        @click="togglePassword"
                        data-test="togglePasswordBtn"
                        size="20"
                        color="medium_grey"
                        >{{ passwordFieldIcon }}</v-icon
                    >
                </template>
            </TextField>
            <p v-if="showError" class="error--text mb-0">
                {{ $t("errors.login_error") }}
            </p>
        </v-card-text>
        <v-card-actions class="pt-0">
            <v-container fluid>
                <v-row justify="center">
                    <v-btn color="secondary"
                        data-test="submit"
                        :loading="loader"
                        :disabled="loader"
                        @click="authorise"
                        >{{ $t("buttons.login") }}</v-btn
                    >
                </v-row>
                <v-row justify="center" class="mt-3">
                    <ResetPassword></ResetPassword>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>

<script>
import TextField from '@/components/widgets/forms/TextField';
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import ResetPassword from '@/components/auth/ResetPassword';

export default {
    name: 'Login',
    components: { ResetPassword, TextField },
    data: () => ({
        loader: false,
        passwordFieldType: 'password',
        passwordFieldIcon: ICONS.DETAILS,
        securityIcon: ICONS.SECURITY,
        showError: false,
        showModal: true
    }),
    computed: {
        ...mapFields('authorization', ['login', 'password']),
        ...mapState('generalConfigurations', [
            'host'
        ])
    },
    methods: {
        authorise () {
            if (this.login && this.password) {
                this.loader = true;
                this.showError = false;
                this.$store
                    .dispatch('authorization/signIn')
                    .then(() => {
                        this.loader = false;
                    })
                    .catch(() => {
                        this.showError = true;
                        this.loader = false;
                    });
            }
        },
        togglePassword () {
            if (this.passwordFieldType === 'password') {
                this.passwordFieldType = 'text';
                this.passwordFieldIcon = ICONS.DETAILS_OFF;
            } else {
                this.passwordFieldType = 'password';
                this.passwordFieldIcon = ICONS.DETAILS;
            }
        },
        clearError () {
            this.showError = false;
            this.loader = false;
        },
        handleLoginEvent (event) {
            this.clearError();
            if (Object.prototype.hasOwnProperty.call(event.data, 'login')) {
                const isLogged = event.data.login === 'true';
                if (isLogged) {
                    this.$store.dispatch('authorization/login').then(() => {
                        // location.reload();
                        this.loader = false;
                        this.$store.commit(
                            'authorization/SET_PREVIOUS_ROUTE',
                            ''
                        );
                    });
                } else if (!isLogged && this.$route.name === 'login') {
                    this.showError = true;
                    this.loader = false;
                }
            }
        }
    },
    watch: {
        login: {
            handler (val, oldVal) {
                if ((val !== oldVal)) {
                    this.clearError();
                }
            },
            immediate: true
        },
        password: {
            handler (val, oldVal) {
                if ((val !== oldVal)) {
                    this.clearError();
                }
            },
            immediate: true
        }
    },
    mounted () {
        window.addEventListener(
            'message',
            event => {
                this.handleLoginEvent(event);
            },
            false
        );
    }
};
</script>

<style scoped lang="scss"></style>
