<template>
    <section>
            <v-btn
                color="primary"
                class="text-lowercase"
                text
                dark
                data-test="resetPasswordBtn"
                @click.stop="dialog = true"
            >
               {{ $t('auth.forgot_pass') }}
            </v-btn>
        <ChangePasswordModal
            :dialog="dialog"
            @close="close"
        ></ChangePasswordModal>
        <NewPasswordModal
            @close="close"
        ></NewPasswordModal>
    </section>
</template>
<script>

import ChangePasswordModal from '@/components/auth/ChangePasswordModal';
import NewPasswordModal from '@/components/auth/NewPasswordModal';

export default {
    name: 'ResetPassword',
    components: {
        NewPasswordModal,
        ChangePasswordModal
    },
    data: () => ({
        dialog: false,
        loader: false
    }),
    methods: {
        close () {
            this.dialog = false;
            this.loader = false;
        }
    }
};
</script>
