<template>
    <v-container fluid class="grid-wrapper pa-0">
        <v-row class="shrink mx-0">
            <TopBar></TopBar>
        </v-row>
        <v-row :align="$vuetify.breakpoint.mdAndUp ? 'stretch' : 'start'"
               class="mx-0"
               justify="center">
            <v-col lg="4" md="5" class="hero__wrapper">
                <div class="hero"></div>
            </v-col>
            <v-col cols="12"
                   :class="{'full-height': $vuetify.breakpoint.smAndDown}"
                   md="7"
                   lg="8">
                <v-layout class="full-height"
                          align-center
                          justify-center
                          column>
                    <Logo width="300px"
                          height="auto"
                          class="mb-1"
                          custom-logo></Logo>
                    <h1 class="font-weight-medium text--l mb-1 mb-3">{{ agencyName }}</h1>
                    <Login class="login"></Login>
                </v-layout>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Login from '@/components/auth/Login';
import Logo from '@/components/widgets/Logo';
import TopBar from '@/components/widgets/TopBar';

export default {
    name: 'Authorization',
    components: { Login, TopBar, Logo },
    computed: {
        ...mapState('generalConfigurations', [
            'agencyConfiguration'
        ]),
        agencyName () {
            if (this.agencyConfiguration) {
                return this.agencyConfiguration.display_name;
            }
            return '';
        }
    },
    beforeCreate () {
        this.$store.dispatch('generalConfigurations/getAgencyConfiguration');
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$store.state.authorization.isLogged) {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .hero {
        background: url("../assets/images/hero.png") no-repeat center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &__wrapper {
            position: relative;
        }
    }

    .brand {
        z-index: 1;
        & h1 {
            text-align: center;
            font-size: $xxl;
        }
    }

    .login {
        width: 40%;
        min-width: 300px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .brand {
            & h1 {
                font-size: $xl;
            }
        }

        .hero {
            &__wrapper {
                display: none;
            }
        }
    }

</style>
