<template>
    <Modal :modal-title="$t('auth.new_pass')"
           :dialog="changePasswordMode"
           max-width="600px"
           :persistent="true"
           :closeIcon="false"
           dense
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
                    <p class="my-3">
                        {{ $t('auth.new_pass_info') }}
                    </p>
                    <ValidationObserver ref="passForm" tag="form" @submit.prevent="sendNewPassword">
                        <TextField
                            slotName="append"
                            :label="$t('labels.password')"
                            name="password"
                            :type="passwordFieldType"
                            :rules="{min: 6, required: true, password:`${passwordConfirmation}`}"
                            v-model="password"
                        >
                            <template slot="slotContent">
                                <v-icon
                                    @click="togglePassword"
                                    size="20"
                                    color="medium_grey"
                                >{{ passwordFieldIcon }}</v-icon
                                >
                            </template>
                        </TextField>
                        <TextField
                            :label="$t('labels.confirm_password')"
                            name="passwordConfirmation"
                            rules="required"
                            type="password"
                            v-model="passwordConfirmation"
                        >
                        </TextField>
                    </ValidationObserver>
        </template>
        <template slot="actions">
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="mr-2" outlined @click="close">
                {{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn color="secondary" @click="sendNewPassword" :loading="loader">
                {{ $t('buttons.save') }}
            </v-btn>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';

export default {
    name: 'newPasswordModal',
    components: { Modal, ValidationObserver, TextField },
    data: () => ({
        loader: false,
        passwordFieldType: 'password',
        passwordFieldIcon: ICONS.DETAILS,
        password: '',
        passwordConfirmation: '',
        changePasswordMode: false,
        ICONS
    }),
    methods: {
        togglePassword () {
            if (this.passwordFieldType === 'password') {
                this.passwordFieldType = 'text';
                this.passwordFieldIcon = ICONS.DETAILS_OFF;
            } else {
                this.passwordFieldType = 'password';
                this.passwordFieldIcon = ICONS.DETAILS;
            }
        },
        close () {
            this.changePasswordMode = false;
            this.$emit('close');
            this.$router.push({ name: 'login' });
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        sendNewPassword () {
            this.loader = true;
            this.$refs.passForm.validate().then(valid => {
                if (valid) {
                    const payload = {
                        ...this.$route.query,
                        'password': this.password,
                        'password_confirmation': this.passwordConfirmation
                    };
                    return this.$store.dispatch('authorization/saveNewPassword', payload)
                        .then(() => {
                            this.loader = false;
                        })
                        .finally(() => {
                            this.close();
                        });
                }
            });
        },
        newPasswordMode () {
            const query = this.$route.query;
            const param = 'reset_password_token';
            return !!Object.getOwnPropertyDescriptor(query, param) && query[param].length > 0;
        }
    },
    created () {
        this.changePasswordMode = this.newPasswordMode();
    }
};
</script>
